import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const FAQ = () => {
  const employeeFAQss = [
    {
      title: "What benefits do employees receive?",
      description:
        "Our company offers a comprehensive benefits package that includes health insurance, retirement plans, paid time off, and professional development opportunities.",
    },
    {
      title: "Is there training provided after hiring?",
      description:
        "Yes, we provide onboarding and training to help new employees become familiar with their roles and the company culture.",
    },
    {
      title: "How do I access my payroll information?",
      description:
        "You can access your payroll details via the employee portal. Reach out to HR for any queries or support.",
    },
  ];

  const jobSeekerFAQs = [
    {
      title: "How can I apply for a job?",
      description:
        "You can browse our job openings on the website and submit your application directly through the job portal. Ensure that your resume is updated before applying.",
    },
    {
      title: "What is the interview process?",
      description:
        "The interview process typically includes an initial screening, followed by one or more rounds of interviews to assess your skills, experience, and cultural fit.",
    },
    {
      title: "How do I know the status of my application?",
      description:
        "Once you've submitted your application, you will receive an email confirmation. Our team will notify you about the next steps as your application progresses.",
    },
  ];
  const [employeeFAQs, setEmployeeFAQs] = useState([]);
  const [activeStep, setActiveStep] = useState(null);
  const [activeSteps, setActiveSteps] = useState(null);

  const handleToggle = (index) => {
    setActiveStep(activeStep === index ? null : index);
  };
  const handleToggles = (index) => {
    setActiveSteps(activeSteps === index ? null : index);
  };
  useEffect(() => {
    FaqApi();
  }, []);
  const FaqApi = () => {
    try {
      axios({
        url: "https://lunarsenterprises.com:5009/choiceglobal/list/faq",
        method: "POST",
      }).then((data) => {
        if (data.data.result) {
          setEmployeeFAQs(data.data.list);
        } else {
          console.log("error", data.data.message);
        }
      });
    } catch (error) {
      console.error("Error deleting service:", error);
    }
  };

  const renderFAQSection = (title, data) => (
    <div className="py-4">
      <motion.div
        className=" mb-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2 className="faq-section-title">{title}</h2>
      </motion.div>

      <div className=" mt-3 ">
        {data?.map((faq, index) => (
          <motion.div
            className=""
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <Card
              className="shadow border-primary mt-3"
              style={{ border: "2px solid #007bff", borderRadius: "8px" }}
            >
              <Card.Body>
                <Card.Title
                  onClick={() => handleToggle(index)}
                  className="d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <span>{faq.question}</span>
                  {activeStep === index ? (
                    <FontAwesomeIcon icon={faAngleUp} />
                  ) : (
                    <FontAwesomeIcon icon={faAngleDown} />
                  )}
                </Card.Title>
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={
                    activeStep === index
                      ? { height: "auto", opacity: 1 }
                      : { height: 0, opacity: 0 }
                  }
                  transition={{ duration: 0.5 }}
                  style={{
                    overflow: "hidden",
                    marginTop: activeStep === index ? "10px" : "0",
                  }}
                >
                  <Card.Text>{faq.answer}</Card.Text>
                </motion.div>
              </Card.Body>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
  const renderFAQSections = (title, data) => (
    <div className="py-4">
      <motion.div
        className=" mb-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2 className="faq-section-title">{title}</h2>
      </motion.div>

      <div className=" mt-3 ">
        {data?.map((faq, index) => (
          <motion.div
            className=""
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <Card
              className="shadow border-primary mt-3"
              style={{ border: "2px solid #007bff", borderRadius: "8px" }}
            >
              <Card.Body>
                <Card.Title
                  onClick={() => handleToggles(index)}
                  className="d-flex justify-content-between align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <span>{faq.question}</span>
                  {activeSteps === index ? (
                    <FontAwesomeIcon icon={faAngleUp} />
                  ) : (
                    <FontAwesomeIcon icon={faAngleDown} />
                  )}
                </Card.Title>
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={
                    activeSteps === index
                      ? { height: "auto", opacity: 1 }
                      : { height: 0, opacity: 0 }
                  }
                  transition={{ duration: 0.5 }}
                  style={{
                    overflow: "hidden",
                    marginTop: activeSteps === index ? "10px" : "0",
                  }}
                >
                  <Card.Text>{faq.answer}</Card.Text>
                </motion.div>
              </Card.Body>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <div className="container py-5">
        <motion.div
          className="text-center mb-5"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1 className=" faq">FAQ's</h1>
        </motion.div>
        <div className="row ">
          <motion.div
            className="col-md-6 mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {renderFAQSection("For Employee", employeeFAQs.Employer)}
          </motion.div>
          <motion.div
            className="col-md-6 mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {renderFAQSections("For Jobseeker", employeeFAQs?.Employee)}
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
