import React from 'react'


import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { IoIosRadioButtonOn } from "react-icons/io";
import Testmonials from './components/Testmonials';
import EnquiryForm from './components/EnquiryForm';
import SocialMediaSidebar from './components/SocialMediaSidebar';
import ScrollArrow from './components/Scrollarrow';

export default function Aviation() {


  const courses = [
    { id: 1, title: 'Ground Training Package CPL', duration: '10 Months', fees: '3 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 2, title: 'Private Pilot License (PPL)', duration: '6 Months', fees: '2 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 3, title: 'Instrument Rating', duration: '8 Months', fees: '2.5 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 4, title: 'Commercial Pilot License (CPL)', duration: '12 Months', fees: '4 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 5, title: 'Multi-Engine Rating', duration: '4 Months', fees: '1.5 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 6, title: 'Flight Instructor Course', duration: '5 Months', fees: '2.2 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 7, title: 'Airline Transport Pilot License (ATPL)', duration: '18 Months', fees: '5 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 8, title: 'Night Rating', duration: '3 Months', fees: '1 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 9, title: 'Type Rating', duration: '6 Months', fees: '3.5 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 10, title: 'Cabin Crew Training', duration: '4 Months', fees: '1.8 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 11, title: 'Aviation Management', duration: '12 Months', fees: '3 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 12, title: 'Aircraft Maintenance Engineering (AME)', duration: '24 Months', fees: '6 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 13, title: 'Airport Ground Services', duration: '6 Months', fees: '2 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 14, title: 'Aviation Security Training', duration: '2 Months', fees: '0.8 L', imageSrc: '/Assets/Aviimg.jpg' },
    { id: 15, title: 'Aviation Safety Management', duration: '9 Months', fees: '3 L', imageSrc: '/Assets/Aviimg.jpg' },
  ];


  return (
    <>
    <head>
      <title>Aviation</title>
    </head>

    <SocialMediaSidebar/>

    <ScrollArrow/>
      {/* Hero banner design */}
      <div className='section herobanneravi  '>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='inner-text'>
                <h1>Fly High with Our <br/> Premier Aviation <br/> Courses</h1>
                <p>Embark on a journey to the skies with our comprehensive aviation training programs designed for aspiring pilots and aviation professionals.</p>
                <a href='/'
                  type="button"
                  className="mt-2 btn btn-primary btn-rounded btn-lg">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

     
      {/* Services
      <div className='section styleposition '>
        <div className='container '>
        <h1 className='mb-5 text-center'>Our Services</h1>
        <div className='col-12'>
          <div className='row  '>
         
            <div className='col-4 align-items-center d-flex justify-content-center'>
              <img src='/Assets/aviation.png' className='Servicesimage' alt='' />
            </div>
            <div className='col-4 align-items-center d-flex justify-content-center'>
            <img src='/Assets/HR.png' className='Servicesimage' alt=''  />
            </div>
            <div className='col-4 align-items-center d-flex justify-content-center'>
              <img src='/Assets/aviation.png' className='Servicesimage' alt='' />
            </div>
            </div>
          </div>
        </div>
      </div>
      <img src='/Assets/Sidebar.png'  width={10} height={50} />

 */}
       {/* About the academy */}
       <div className='section '>
       <div className='container'>
         
         <div className='d-flex align-items-center'>
         <img src='/Assets/Sidebar.png'  width={10} height={50}/>
         <h1 className='Headtitle hovereffecthead'>ABOUT THE ACADEMY</h1>
         </div>
           
           <div className='col-lg-12'>
             <div className='inner-two subtitlepara'>
             
               <p>Our academy is dedicated to providing top-notch education and training in the field of aviation. We aim to equip our students with the knowledge and skills required to excel in various aviation careers. Our curriculum is designed to be comprehensive and up-to-date with industry standards, ensuring that our graduates are well-prepared for the dynamic world of aviation.
               We are committed to fostering a learning environment that promotes growth, innovation, and excellence. Our state-of-the-art facilities and advanced training equipment ensure that our students receive hands-on experience in a real-world setting. Additionally, we provide personalized support and guidance to help each student achieve their career goals.
               </p>
             </div>
           </div>
         </div>
       </div>


         {/* Our courses */}
         <div className='   coursebg  pt-5'>
         <div className='container '>
           
           <div className='d-flex align-items-center '>
           <img src='/Assets/Sidebar.png'  width={10} height={50}/>
           <h1 className='Headtitle hovereffecthead '>OUR COURSES</h1>
           </div>
             
             <div className='col-lg-12'>
               <div className='inner-two subtitlepara row'>
  <div className='col-md-4'>
  <h2 className='titlenamec mb-4'>Pilot Training</h2>
  <p className='bounce coursehove'> <IoIosRadioButtonOn color='#2e738c' />  Private Pilot License (PPL)</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Commercial Pilot License (CPL)</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Airline Transport Pilot License (ATPL)</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Multi-Engine Rating</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />   Instrument Rating (IR)</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Certified Flight Instructor (CFI)</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Certified Flight Instructor – Instrument (CFII)</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Multi-Engine Instructor (MEI)</p>
  </div>

  <div className='col-md-4'>
  <h2 className='titlenamec mb-4'>Aircraft Maintenance</h2>

  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Aircraft Maintenance Engineer (AME)</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Avionics Technician</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Airframe and Powerplant (A&P) Mechanic</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Repairman Certificate</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Aircraft Systems Technician</p>
  </div>

  <div className='col-md-4'>
  <h2 className='titlenamec mb-4'>Aviation Management</h2>

  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Bachelor's in Aviation Management</p>
  <p className='bounce coursehove'> <IoIosRadioButtonOn color='#2e738c' />  Master's in Aviation Management</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Aviation Safety Management Systems</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />  Airport Operations Management</p>
  <p className='bounce coursehove'><IoIosRadioButtonOn color='#2e738c' />   Airline Operations Management</p>
  </div>
               </div>
             </div>
           </div>
         </div>
     
         <div className="section herobannerbatch ">
         <div className="container">
           <div>
             <h1>Admission for new batch is open now (offline & online).</h1>
           </div>
         </div>
       </div>
       

       {/* COURSES OFFERED & FEE STRUCTURE  */}
       <div className="section ">
       <div className="container">
       <div className='d-flex align-items-center mb-5 '>
       <img src='/Assets/Sidebar.png'  width={10} height={50}/>
       <h1 className='Headtitle hovereffecthead '>COURSES OFFERED & FEE STRUCTURE</h1>
       </div>
       <Testmonials/>
       </div>
     </div>


{/* our placement */}


<div className="section ">
<div className="container">
<div className='d-flex align-items-center mb-5 '>
<img src='/Assets/Sidebar.png'  width={10} height={50}/>
<h1 className='Headtitle hovereffecthead '>OUR PLACEMENTS</h1>
</div>

<div className="solution-itemgrid">
{courses.map((course) => (
  <div className="solution-itemgridsec bounce " key={course.id}>
    <div className="imgDiv-carousel imageborder">
      <img
        src={course.imageSrc}
        alt="partners_logo"
        className="img-responsive  imageborder border-4"
      />
    </div>
    <div className="slideSecond">
      <h6 className="mt-2 cebtt">{course.title}</h6>
      
      <h6 className="mt-2 cebtt">Indigo</h6>
      
      <p className=" cebtt">Salary: {course.fees}</p>
    </div>
  </div>
))}
</div>
</div>
</div>



  {/* {certified} */}

  <div className="section ">
  <div className="container">
  <div className='d-flex align-items-center mb-5 '>
  <img src='/Assets/Sidebar.png'  width={10} height={50}/>
  <h1 className='Headtitle hovereffecthead pt-2'>WE ARE CERTIFIED BY</h1>
  </div>
  <Testmonials/>
  </div>
</div>





     

<EnquiryForm/>

    </>
  )
}


