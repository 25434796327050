import React from "react";
import { motion } from "framer-motion";
import goldstar from "../../components/Assets/star-medal.png";
import handshake from "../../components/Assets/networking.png";
import target from "../../components/Assets/target.png";
import bulb from "../../components/Assets/project-management.png";
import commit from "../../components/Assets/push-pin.png";
import adapt from "../../components/Assets/worldwide.png";

const MissionVisionValues = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <div className="herobannermission py-5">
      <div className="container text-white">
        {/* Values Section */}
        {/* <div className="services py-5">
          <div className="container">
            <h3 className="title  mb-5 main-heading">
              Our <span className="highlighted-text">Values</span>{" "}
            </h3>
            <motion.div
              className="row text-center"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
            >
              <div className="col-md-4">
                <div className="serv">
                  <div className="square">
                    <i className="fas fa-code">
                      <img src={goldstar} className="icons-sizing" />
                    </i>
                  </div>
                  <h5>Quality</h5>
                  <p>
                    We are dedicated to providing the highest-quality talent
                    that meets each client's unique needs. Excellence is our
                    standard, and we never compromise on the calibre of our
                    hires.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="serv">
                  <div className="square">
                    <i className="fas fa-cogs">
                      <img src={handshake} className="icons-sizing" />
                    </i>
                  </div>
                  <h5>Integrity</h5>
                  <p>
                    We conduct our business with honesty, transparency, and
                    respect. Trust is at the heart of our relationships with
                    clients and candidates alike.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="serv">
                  <div className="square">
                    <i className="fas fa-globe">
                      <img src={target} className="icons-sizing" />
                    </i>
                  </div>
                  <h5>Client-Centric Approach</h5>
                  <p>
                    We prioritise our clients' goals, striving to understand
                    their needs and delivering staffing solutions that
                    contribute to their success. Your business objectives are
                    our top priority.
                  </p>
                </div>
              </div>
            </motion.div>

            <motion.div
              className="row text-center mt-4"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
            >
              <div className="col-md-4">
                <div className="serv">
                  <div className="square">
                    <i className="fas fa-lightbulb">
                      <img src={bulb} className="icons-sizing" />
                    </i>
                  </div>
                  <h5>Innovation</h5>
                  <p>
                    We continuously seek new and innovative ways to enhance our
                    recruitment processes, ensuring that we stay ahead of
                    industry trends and provide cutting-edge solutions.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="serv">
                  <div className="square">
                    <i className="fas fa-user-shield">
                      <img src={commit} className="icons-sizing" />
                    </i>
                  </div>
                  <h5>Commitment</h5>
                  <p>
                    We are dedicated to long-term partnerships, supporting both
                    businesses and job seekers at every step of the recruitment
                    journey. Our commitment goes beyond placement—we aim for
                    lasting success.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="serv">
                  <div className="square">
                    <i className="fas fa-exchange-alt">
                      <img src={adapt} className="icons-sizing" />
                    </i>
                  </div>
                  <h5>Adaptability</h5>
                  <p>
                    We embrace change and remain flexible, tailoring our
                    services to evolving market demands and our clients'
                    specific needs.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div> */}
        <div class="custom-core-values-container">
          <h3 class="custom-core-values-title">
            Our <span className="highlighted-text">Values</span>{" "}
          </h3>

          <div class="custom-core-values">
            <motion.div
              class="custom-value-item"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
            >
              <img src={goldstar} alt="Quality" class="custom-value-image" />
              <div class="custom-value-content">
                <h5>Quality</h5>
                <p>
                  We deliver nothing less than excellence, providing top-tier
                  talent that perfectly matches your needs. Quality is our
                  promise, and we never settle for less.
                </p>
              </div>
            </motion.div>

            <motion.div
              class="custom-value-item"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
            >
              <img src={handshake} alt="Ethics" class="custom-value-image" />
              <div class="custom-value-content">
                <h5>Integrity</h5>
                <p>
                  Honesty and transparency are our foundation. Trust is built
                  with every action, fostering lasting relationships with both
                  clients and candidates
                </p>
              </div>
            </motion.div>

            <motion.div
              class="custom-value-item"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
            >
              <img src={target} alt="Motivation" class="custom-value-image" />
              <div class="custom-value-content">
                <h5>Client-Centric Approach</h5>
                <p>
                  Client's success is our mission. We go above and beyond to
                  understand your goals and deliver staffing solutions that make
                  a real impact on your business.
                </p>
              </div>
            </motion.div>

            <motion.div
              class="custom-value-item"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
            >
              <img src={bulb} alt="Innovation" class="custom-value-image" />
              <div class="custom-value-content">
                <h5>Innovation</h5>
                <p>
                  We’re always ahead of the curve, continuously refining our
                  processes with the latest industry trends to offer you
                  cutting-edge recruitment solutions.
                </p>
              </div>
            </motion.div>

            <motion.div
              class="custom-value-item"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
            >
              <img src={commit} alt="Excellence" class="custom-value-image" />
              <div class="custom-value-content">
                <h5>Commitment</h5>
                <p>
                  Our partnership doesn’t end at placement. We’re committed to
                  your long-term success, supporting your growth at every step
                  of the hiring journey.
                </p>
              </div>
            </motion.div>

            <motion.div
              class="custom-value-item"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
            >
              <img src={adapt} alt="Reliability" class="custom-value-image" />
              <div class="custom-value-content">
                <h5>Adaptability</h5>
                <p>
                  Change is our ally. We tailor our solutions to meet your
                  evolving needs, ensuring you always have the right talent, no
                  matter the market shifts.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
        <div className="row align-items-center g-5">
          {/* Mission Section */}
          <motion.div
            className="col-lg-6"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
          >
            <div className="p-4 bg-opacity-75 background-protector">
              <h2 className="mb-4 text-uppercase">Our Mission</h2>
              <p>
                At Choice Global Business, we aim to empower organisations by
                connecting them with exceptional talent. We are dedicated to
                providing high-performance staffing solutions that drive success
                for businesses in Oman and beyond. Our commitment is to deliver
                reliable, skilled, and adaptable professionals tailored to the
                unique demands of each industry we serve. We strive to be a
                trusted partner in recruitment, ensuring quality hires that fill
                positions and help organisations thrive and grow.
              </p>
            </div>
          </motion.div>

          {/* Vision Section */}
          <motion.div
            className="col-lg-6"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
          >
            <div className="p-4 bg-opacity-75 background-protector">
              <h2 className="mb-4 text-uppercase">Our Vision</h2>
              <p>
                Our vision at Choice Global Business is to be the leading
                staffing agency in the region, known for excellence in
                connecting the right talent with the right opportunities. We aim
                to redefine recruitment standards by delivering top-tier
                professionals who drive progress and innovation across diverse
                industries. We envision a future where our expertise empowers
                businesses to thrive and candidates find fulfilling careers
                aligning with their skills and aspirations.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default MissionVisionValues;
