import React from "react";
import { motion } from "framer-motion";
import proven from "../../components/Assets/proven.png";
import reliable from "../../components/Assets/reliable.png";
import tailored from "../../components/Assets/tailored.png";
import planet from "../../components/Assets/planet.png";
import commit from "../../components/Assets/commitment.png";
import transparent from "../../components/Assets/transparet.png";
import post from "../../components/Assets/post.png";
import industry from "../../components/Assets/industry.png";
import strong from "../../components/Assets/strong.png";
import trust from "../../components/Assets/trusted.png";

const Whyworkwithus = () => {
  const steps = [
    {
      icon: proven,
      title: "Proven Expertise",
      desc: "Years of experience in recruitment across various industries ensure we find the right talent for your needs.",
    },
    {
      icon: planet,
      title: "Quality Talent Pool",
      desc: "We connect you with top candidates through our diverse network and thorough screening process.",
    },
    {
      icon: tailored,
      title: "Tailored Solutions",
      desc: "Our staffing solutions are customized to meet your specific hiring requirements and business goals.",
    },
    {
      icon: reliable,
      title: "Quick and Reliable Service",
      desc: "Our efficient process delivers qualified candidates quickly, saving you time and effort.",
    },
    {
      icon: commit,
      title: "Commitment to Excellence",
      desc: "We deliver exceptional staffing solutions focused on quality and client satisfaction.",
    },
    {
      icon: transparent,
      title: "Transparent Communication",
      desc: "We keep you informed throughout the recruitment process with clear, open communication.",
    },
    {
      icon: post,
      title: "Post-Hire Support",
      desc: "We provide support even after placement to ensure long-term success for your hires.",
    },
    {
      icon: industry,
      title: "Industry Insights",
      desc: "We offer insights on industry trends to help you make informed hiring decisions.",
    },
    {
      icon: strong,
      title: "Strong Client Relationships",
      desc: "We build lasting partnerships by understanding your business and aligning with your goals.",
    },
    {
      icon: trust,
      title: "Trusted Reputation",
      desc: "Our proven track record and satisfied clients highlight our reliability and professionalism.",
    },
  ];

  return (
    <div className="why-choose-us">
      <motion.div
        // className=" text-start position-relative"
        style={{ marginBottom: "20px" }}
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className="heading">
          Why <span className="highlighted-text">Work With Us</span>
        </h1>
        <p className=" pstyles para-styling" style={{ marginBottom: "50px" }}>
          At Choice Global Business, we pride ourselves on a seamless and open
          process, delivering exceptional talent tailored to your unique
          business requirements.
        </p>
      </motion.div>
      <motion.div
        className="features-container"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        {steps.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="icon-containers">
              <img src={feature.icon} alt={feature.title} className="icons" />
            </div>
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-desc">{feature.desc}</p>
          </div>
        ))}
      </motion.div>
    </div>
  );
};
export default Whyworkwithus;
