import React, { useEffect, useRef, useState } from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import EnquiryForm from "./components/EnquiryForm";
import SocialMediaSidebar from "./components/SocialMediaSidebar";
import ScrollArrow from "./components/Scrollarrow";
import { FaBuilding, FaLocationDot } from "react-icons/fa6";
import axios from "axios";
import moment from "moment/moment";
import CareerList from "./Pages/Joblist/Joblist";
import FAQ from "./Pages/FAQ/FAQ";
import Whyworkwithus from "./Pages/Howtowork/whyworkwithus";

export default function Manpower() {
  const splideRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const goToSlide = (index) => {
    if (splideRef.current) {
      splideRef.current.go(index);
      setActiveIndex(index);
    }
  };

  return (
    <>
      <head>
        <title>Aviation</title>
      </head>

      <SocialMediaSidebar />

      <ScrollArrow />
      {/* Hero banner design */}
      <div className="section herobanneraviman  ">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="inner-text">
                <h1 style={{ color: "white" }}>
                  Your Future Starts Here: Build a Career That Matters
                </h1>
                <p>
                  Unlock endless job opportunities—where your skills meet the
                  roles that matter most.
                </p>
                {/* <a
                  href="/#contactus"
                  type="button"
                  className="mt-2 btn btn-primary btn-rounded btn-lg"
                >
                  Contact Us
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* About the academy */}
      {/* <div className="section ">
        <div className="container">
          <div className="d-flex align-items-center">
            <img src="/Assets/Sidebar.png" width={10} height={50} />
            <h1 className="Headtitle">CATEGORIES</h1>
          </div>
          <div className="container">
            <div className="solution-itemgridcate mt-5">
              {servicesList.map((course) => (
                <div
                  className="solution-itemgridseccate bounce "
                  key={course.id}
                >
                  <div className="sldeSecond">
                    <h6 className="mt-2 cate">{course.title}</h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}

      {/* JOB OPPORTUNITIES */}
      {/* 
      <div className="section ">
        <div className="container">
          <div className="d-flex align-items-center mb-5 ">
            <img src="/Assets/Sidebar.png" width={10} height={50} />
            <h1 className="Headtitle ">JOB OPPORTUNITIES</h1>
          </div>

          <div className="solution-itemgrid">
            {servicesList.map((course) => (
              <div className="solution-itemgridsec bounce " key={course.id}>
                <div className="imgDiv-carousel"></div>
                <div className="slideSecondcate">
                  <h5 className="mt-2 ">{course.jb_name}</h5>
                  <p className=" cebttop">
                    Closing on{" "}
                    {moment(course.jb_closing_date).format("DD MMM YYYY")}
                  </p>
                  <p className=" cebttop">
                    <FaLocationDot /> {course.jb_location}
                  </p>
                  <p className=" cebttop">
                    <FaBuilding />
                    {course.jb_type}
                  </p>
                  <a href="/" type="button" className="mt-2 buttoncustm mb-2">
                    Contact Us
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      <CareerList />
      <Whyworkwithus />
      {/* <div className="bg-bannerimagefaq">
        <FAQ />
      </div> */}
      {/* RECRUITMENT  */}
      {/* <div className="section ">
        <div className="container">
          <div className="d-flex align-items-center  ">
            <img src="/Assets/Sidebar.png" width={10} height={50} />
            <h1 className="Headtitle ">OUR RECRUITMENT PARTNERS </h1>
          </div>
          <div className="section solution-slider">
            <div className="container">
              <div className="section-slider text-center">
                <Splide
                  options={{
                    perPage: 3,
                    rewind: true,
                    autoplay: true,
                    interval: 6000,
                    pagination: false,
                    gap: "1rem",
                    breakpoints: {
                      1200: {
                        perPage: 4,
                        gap: "1rem",
                      },
                      768: {
                        perPage: 2,
                        gap: "0.5rem",
                      },
                      480: {
                        perPage: 1,
                        gap: "0.25rem",
                      },
                    },
                  }}
                  aria-label="customList"
                  ref={splideRef}
                  onMoved={(splide, newIndex) => setActiveIndex(newIndex)}
                >
                  <SplideSlide>
                    <div className="solution-item bounce">
                      <div className="imgDiv-carousel">
                        <img
                          src="/Assets/Aviimg.jpg"
                          alt="partners_logo"
                          className="imgDiv-carousel"
                        />
                      </div>
                    </div>
                  </SplideSlide>

                  <SplideSlide>
                    <div className="solution-item bounce">
                      <div className="imgDiv-carousel">
                        <img
                          src="/Assets/Aviimg.jpg"
                          alt="partners_logo"
                          className="img-responsive"
                        />
                      </div>
                    </div>
                  </SplideSlide>

                  <SplideSlide>
                    <div className="solution-item  bounce">
                      <div className="imgDiv-carousel">
                        <img
                          src="/Assets/Aviimg.jpg"
                          alt="partners_logo"
                          className="img-responsive"
                        />
                      </div>
                    </div>
                  </SplideSlide>

                  <SplideSlide>
                    <div className="solution-item bounce">
                      <div className="imgDiv-carousel">
                        <img
                          src="/Assets/Aviimg.jpg"
                          alt="partners_logo"
                          className="img-responsive"
                        />
                      </div>
                    </div>
                  </SplideSlide>

                  <SplideSlide>
                    <div className="solution-item bounce">
                      <div className="imgDiv-carousel">
                        <img
                          src="/Assets/Aviimg.jpg"
                          alt="partners_logo"
                          className="img-responsive"
                        />
                      </div>
                    </div>
                  </SplideSlide>
                </Splide>
                Custom indicators 
           <div className="custom-indicators">
             <button
               className={activeIndex === 0 ? "active" : ""}
               onClick={() => goToSlide(0)}
             ></button>
             <button
               className={activeIndex === 1 ? "active" : ""}
               onClick={() => goToSlide(1)}
             ></button>
             <button
               className={activeIndex === 2 ? "active" : ""}
               onClick={() => goToSlide(2)}
             ></button>
             <button
               className={activeIndex === 3 ? "active" : ""}
               onClick={() => goToSlide(3)}
             ></button>
             <button
               className={activeIndex === 4 ? "active" : ""}
               onClick={() => goToSlide(4)}
             ></button>
           </div>
          
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <EnquiryForm /> */}
    </>
  );
}
