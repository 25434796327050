import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import axios from "axios";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = () => {
    axios({
      url: "https://lunarsenterprises.com:5009/choiceglobal/list/testimonials",
      method: "POST",
    })
      .then((data) => {
        if (data.data.result) {
          setTestimonials(data.data.list);
        } else {
          console.log("Error:", data.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const baseurl = "https://lunarsenterprises.com:6002/";

  return (
    <div className="testimonial-section">
      <h2 className="section-title">
        What Our <span className="highlighted-text">Client Says?</span>
      </h2>
      {testimonials.length > 0 ? (
        <Splide
          options={{
            type: "loop",
            perPage: 2,
            autoplay: true,
            interval: 5000,
            pauseOnHover: true,
            pagination: true,
            arrows: false,
            speed: 800,

            breakpoints: {
              768: {
                perPage: 1, // Display 1 slide per page for screens <= 768px
              },
              1024: {
                perPage: 2, // Display 2 slides per page for screens <= 1024px
              },
            },
          }}
          className="testimonial-slider"
        >
          {testimonials.map((item, index) => (
            <SplideSlide key={index}>
              <div className="testimonial-card">
                <img
                  src={baseurl + item.t_image}
                  alt={item.t_name}
                  className="testimonial-image"
                />
                <blockquote style={{ fontSize: "18px", fontStyle: "italic" }}>
                  <p className="testimonial-description">
                    "{item.t_description}"
                  </p>
                </blockquote>
                <p className="testimonial-author">
                  - {item.t_name}, <span>({item.t_position})</span>
                </p>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      ) : null}
    </div>
  );
};

export default Testimonials;
