import React from "react";
import EnquiryForm from "../../components/EnquiryForm";
import SocialMediaSidebar from "../../components/SocialMediaSidebar";
import ScrollArrow from "../../components/Scrollarrow";

function ContactUs() {
  return (
    <div>
      <head>
        <title>Aviation</title>
      </head>
      <div style={{ marginTop: "80px" }}>
        <SocialMediaSidebar />

        <ScrollArrow />
        <EnquiryForm />
      </div>
    </div>
  );
}

export default ContactUs;
