import React, { useState } from "react";
import { motion } from "framer-motion";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import one from "../../components/Assets/number-one.png";
import two from "../../components/Assets/number-2.png";
import three from "../../components/Assets/number-3.png";
import four from "../../components/Assets/number-four.png";
import five from "../../components/Assets/number-5.png";
import six from "../../components/Assets/six.png";
import seven from "../../components/Assets/seven.png";

const HowWeWork = () => {
  const steps = [
    {
      icons: one,
      title: "Consultation for Manpower Requirements",
      description: `At Choice Global, we understand that the right workforce is 
crucial to the success of your business. Our expert consultation services 
are designed to help you address your manpower needs efficiently and 
effectively, ensuring that you have the right people in the right roles at 
the right time.`,
      list: [
        "Understanding Your Business Needs",
        "Assessing Workforce Gaps",
        "Providing Strategic Manpower Solutions",
        "Streamlining the Recruitment Process",
        "Implementation and Support",
      ],
    },
    {
      icons: two,
      title: "Talent Sourcing",
      description: `At Choice Global, we know that the success of your business 
hinges on the talent you hire. Our expert talent sourcing services are 
designed to connect you with the right candidates who not only fit your 
current workforce needs but also align with your company culture and 
long-term business goals.`,
      list: [
        "Understanding Your Talent Needs",
        "Building a Targeted Talent Pool",
        "Screening and Shortlisting Candidates",
        "Presenting Top Candidates",
        "Facilitating the Interview Process",
      ],
    },
    {
      icons: three,
      title: "Screening & Evaluation",
      description: `At Choice Global, we understand that hiring the right talent is a 
critical factor in driving your business success. Our Screening & 
Evaluation process ensures that you only receive candidates who are 
not only qualified but also aligned with your company’s values and 
objectives.`,
      list: [
        "Comprehensive Screening Process",
        "In-Depth Evaluation",
        "Ensuring Cultural Fit",
        "Transparent Reporting",
      ],
    },
    {
      icons: four,
      title: "Shortlisting & Presentation",
      description: `At Choice Global, our goal is to help you identify the most qualified 
and suitable candidates efficiently and effectively. Our Shortlisting & 
Presentation process is designed to streamline your hiring decisions, 
providing you with a curated list of top talent that matches your specific 
needs.`,
      list: [
        "Understanding Your Requirements",
        "Thorough Shortlisting Process",
        "Customized Candidate Profiles",
        "Candidate Presentation",
      ],
    },
    {
      icons: five,
      title: "Interview Coordination",
      description: `At Choice Global, we understand that the interview process can be a 
critical and time-consuming part of recruitment. Our Interview 
Coordination service is designed to streamline this process, ensuring 
that everything runs smoothly, efficiently, and according to your 
schedule.`,
      list: [
        "Seamless Scheduling",
        "Customized Interview Formats",
        "Candidate Preparation",
        "Interview Logistics Management",
        "Real-Time Support",
      ],
    },
    {
      icons: six,
      title: "Placement & Onboarding",
      description: `At Choice Global, we believe that the process of placement and 
onboarding is crucial to ensuring a smooth transition for new hires while 
aligning with your organization's goals and culture.`,
      list: [
        "Pre-Onboarding Preparation",
        "Welcome and Orientation",
        "Training and Development",
        "Continuous Support",
      ],
    },
    {
      icons: seven,
      title: "Post-Placement Support",
      description: `At Choice Global, our commitment to both clients and 
candidates extends far beyond the placement process. We believe that 
successful talent management requires continuous support, ensuring 
that both the new hires and your organization thrive in the long term.`,
      list: [
        "Regular Follow-Ups",
        "Addressing Challenges",
        "Ensuring Long-Term Fit",
        "Continuous Feedback and Support",
      ],
    },
  ];

  // Split the steps into two halves
  const firstHalf = steps.slice(0, Math.ceil(steps.length / 2));
  const secondHalf = steps.slice(Math.ceil(steps.length / 2));

  const [activeStep, setActiveStep] = useState(null);

  const handleToggle = (index) => {
    setActiveStep(activeStep === index ? null : index);
  };

  const renderSteps = (stepList, offset) =>
    stepList.map((step, index) => (
      <motion.div
        className="col-md-12"
        key={index + offset}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: (index + offset) * 0.1 }}
      >
        <Card className="shadow" style={{ margin: "15px" }}>
          <Card.Body>
            <Card.Title
              onClick={() => handleToggle(index + offset)}
              className="d-flex justify-content-between align-items-center"
              style={{ cursor: "pointer" }}
            >
              <span>
                <img
                  src={step.icons}
                  style={{ height: "30px", width: "30px", marginRight: "10px" }}
                />{" "}
                {step.title}
              </span>
              {activeStep === index + offset ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </Card.Title>
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={
                activeStep === index + offset
                  ? { height: "auto", opacity: 1 }
                  : { height: 0, opacity: 0 }
              }
              transition={{ duration: 0.5 }}
              style={{
                overflow: "hidden",
                marginTop: activeStep === index + offset ? "10px" : "0",
              }}
            >
              <Card.Text>{step.description}</Card.Text>
              <ul>
                {step.list.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </motion.div>
          </Card.Body>
        </Card>
      </motion.div>
    ));

  return (
    <div className="sliderServices">
      <div style={{ padding: "10px" }} />
      <motion.div
        className="text-center mb-5"
        // style={{ marginTop: "20px" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1 className="display-4 colorstyle main-heading">
          How <span className="highlighted-text">We Work</span>{" "}
        </h1>
        <p className="lead pstyle" style={{ textAlign: "center" }}>
          At Choice Global, we follow a structured and transparent process to
          ensure our clients receive the best talent for their needs.
        </p>
      </motion.div>

      {/* <div className="row"> */}
      <div className="col-md-6">
        {renderSteps(firstHalf, 0)}
        {renderSteps(secondHalf, firstHalf.length)}
      </div>
      {/* <div className="col-md-6" style={{ gap: "5px" }}></div> */}
      {/* </div> */}
    </div>
  );
};

export default HowWeWork;
