import React, { useEffect, useState } from "react";
import SocialMediaSidebar from "./components/SocialMediaSidebar";
import ScrollArrow from "./components/Scrollarrow";
import staffing from "./components/Assets/EventStaffings.png";
import background from "./components/Assets/permenantstaffing.jpg";
import carrier from "./components/Assets/backgroundcarrier.jpg";
import backmain from "./components/Assets/BussinessStaffing.jpg";
import HowWeWork from "./Pages/Howtowork/Howtowork";
import { useParams } from "react-router-dom";
import rightarrow from "../src/components/Assets/right-arrow.png";

const ServicePage = () => {
  const { serviceTitle } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [serviceTitle]);
  const services = [
    {
      title: "Permanent Staffing",
      content:
        "Permanent staffing involves hiring employees for long-term positions, often with a permanent contract and benefits. These employees become an integral part of the company and are expected to contribute to its long-term goals.",
      point1:
        "Long-Term Stability: Permanent staff provide continuity and consistency in the workforce.",
      point2:
        "Higher Productivity: Permanent employees are more invested in the company’s success, leading to higher motivation and productivity.",
      point3:
        "Cost Efficiency: Reduces the need for frequent hiring and training costs compared to temporary staff.",
      point4:
        "Deep Knowledge: Employees develop a thorough understanding of the company's processes, culture, and long-term goals.",
      point5:
        "Stronger Team Morale: Offering job security leads to higher employee satisfaction and retention.",
      img: background,
    },
    {
      title: "Temporary Staffing",
      content:
        "Temporary staffing involves hiring workers for a fixed period or specific tasks, typically to meet short-term business needs such as seasonal demands, project-based work, or covering for absent employees. Temporary employees work for a set duration and are often employed through staffing agencies. They don’t receive the same benefits as permanent staff and can be hired quickly to fill immediate gaps in the workforce.",
      point1:
        "Flexibility: Quickly adjust to fluctuating workloads without long-term commitments.",
      point2:
        "Cost-Efficiency: Save on benefits and long-term salaries for short-term needs.",
      point3:
        "Immediate Availability: Temporary workers can be onboarded quickly for urgent projects or events.",
      point4:
        "Reduced Risk: Temporary staffing allows employers to evaluate workers before offering permanent positions.",
      point5:
        "Access to Specialized Skills: Gain temporary access to skilled professionals for niche or specialized tasks without long-term obligations.",
      img: carrier,
    },
    {
      title: "Event Staffing",
      content:
        "Event staffing involves hiring temporary employees for specific events, such as conferences, trade shows, festivals, or corporate functions. These workers are hired for a short duration to perform tasks such as registration, customer service, catering, or security.",
      point1:
        "Flexibility: Quickly hire the right number of staff for events without long-term commitments",
      point2:
        "Cost Efficiency: Only pay for the staffing you need, avoiding long-term salary costs.",
      point3:
        "Expertise: Access to trained professionals who specialize in event-related tasks.",
      point4:
        "Quick Onboarding: Temporary staff can be hired and trained swiftly, ensuring smooth event execution.",
      point5:
        "Scalability: Adjust staffing levels depending on the size and requirements of each event.",
      img: staffing,
    },
    {
      title: "Business Staffing",
      content:
        "Business staffing refers to hiring employees to fulfill various roles within a company to support its day-to-day operations, including administration, marketing, sales, IT, and more. This can involve both permanent and temporary hires depending on the needs of the business.",
      point1:
        "Operational Efficiency: Skilled workers help improve business performance and streamline day-to-day operations.",
      point2:
        "Cost Management: Tailor your staffing needs to match business goals, reducing overhead costs by adjusting staff size.",
      point3:
        "Specialized Expertise: Hire employees with specific skills that are critical to business functions, such as IT support, HR, or finance.",
      point4:
        "Scalability: Easily scale your workforce based on fluctuating business demands or projects.",
      point5:
        "Focus on Core Business: With the right team in place, you can focus on strategic growth while your staff handles operational tasks.",
      img: backmain,
    },
  ];

  const [selectedService, setSelectedService] = useState(services[0]);

  useEffect(() => {
    const matchedService = services.find(
      (service) => service.title === decodeURIComponent(serviceTitle)
    );
    if (matchedService) setSelectedService(matchedService);
  }, [serviceTitle]);

  return (
    <>
      <SocialMediaSidebar />

      <ScrollArrow />
      {/* Hero banner design */}
      <div className="section herobanneravimans">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="inner-text">
                <h1 style={{ color: "#fff" }}>
                  Empowering Businesses with Tailored Staffing Solutions
                </h1>
                <p>
                  Right talent, right time—because your success starts with
                  the perfect team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <h1 className="main-heading">
          Our <span className="highlighted-text">Services</span>
        </h1>
      </div>
      <div className="service-page-container">
        <div className="service-page-layout">
          {/* Left Section */}
          <div className="service-page-left">
            {services.map((service, index) => (
              <div
                key={index}
                onClick={() => setSelectedService(service)}
                className={`service-page-item ${
                  selectedService.title === service.title ? "active" : ""
                }`}
              >
                <span>{service.title}</span>
                <span>➔</span>
              </div>
            ))}
            <div className="container-new-for-small-image">
              <div className="background-smaller-image">
                <h3 className="service-sidebar__contact__title">
                  Recruitment Solutions for all Businesses
                </h3>
                <a className="hiredots-btn">Contact</a>
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="service-page-right">
            <img
              src={selectedService.img}
              alt={selectedService.title}
              className="service-page-image"
            />
            <h2 className="service-page-title">{selectedService.title}</h2>
            <p className="service-page-description">
              {selectedService.content}
            </p>
            <h2 className="service-page-title">Benefits</h2>
            <div className="dividers">
              <img src={rightarrow} className="Bullet-in" />
              <p className="service-page-description">
                {selectedService?.point1}
              </p>
            </div>
            <div className="dividers">
              <img src={rightarrow} className="Bullet-in" />
              <p className="service-page-description">
                {selectedService?.point2}
              </p>
            </div>
            <div className="dividers">
              <img src={rightarrow} className="Bullet-in" />
              <p className="service-page-description">
                {selectedService?.point3}
              </p>
            </div>
            <div className="dividers">
              <img src={rightarrow} className="Bullet-in" />
              <p className="service-page-description">
                {selectedService?.point4}
              </p>
            </div>
            <div className="dividers">
              <img src={rightarrow} className="Bullet-in" />
              <p className="service-page-description">
                {selectedService?.point5}
              </p>
            </div>
          </div>
        </div>
      </div>

      <HowWeWork />
    </>
  );
};

export default ServicePage;
