import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./Industries.css";
import axios from "axios";
import icon1 from "../../components/Assets/hiring.png";
import icon2 from "../../components/Assets/recruitment.png";
import icon3 from "../../components/Assets/recruitments.png";
import icon4 from "../../components/Assets/headhunting.png";
import icon5 from "../../components/Assets/recruit.png";

const Industries = () => {
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, duration: 0.5 },
    }),
    hover: { scale: 1.05, boxShadow: "0px 10px 15px rgba(0,0,0,0.2)" },
  };

  useEffect(() => {
    ServicesApi();
  }, []);
  const randomImages = [icon1, icon2, icon3, icon4, icon5];
  const [servicesList, setServicesList] = useState([]);

  const ServicesApi = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5009/choiceglobal/list/services"
      );

      console.log(response.data.list, "datas");

      if (response.data.result === true) {
        setServicesList(response.data.list);
      } else {
        alert("Api Fetch issues");
      }
    } catch (error) {}
  };

  // Function to select a random image from the array
  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * randomImages.length);
    return randomImages[randomIndex];
  };
  const baseurl = "https://lunarsenterprises.com:5009/";
  return (
    <section
      className="industries-section bg-bannerimage"
      id="PermanentStaffing"
    >
      <div className="container">
        <motion.div
          className="header"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="section-container">
            <h1 className="main-heading">
              Industries <span className="highlighted-text">We Serve</span>
            </h1>
            <p className="description-text">
              At Choice Global Business, we pride ourselves on our expertise
              across various industries. Our goal is to deliver the right
              talent, tailored to
              <br /> meet the unique demands of your sector:
            </p>
          </div>
        </motion.div>
        <div className="industries-grid">
          {servicesList.map((industry, index) => (
            <motion.div
              key={industry.id}
              className="industry-card"
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              custom={index}
            >
              {/* Display a random image */}
              {industry.s_image ? (
                <img
                  src={baseurl + industry.s_image}
                  alt={`${industry.s_name} industry`}
                  className="industry-image"
                  style={{
                    height: "100px",
                    // width: "100px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <img
                  src={getRandomImage()}
                  alt={`${industry.s_name} industry`}
                  className="industry-image"
                  style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "cover",
                  }}
                />
              )}
              <h4 style={{ height: "60px" }}>{industry.s_name}</h4>
              <p className="pstyle">{industry.s_description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Industries;
