// src/App.js
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Aviation from "./Aviation";
import Header from "./components/Header";
import "./Styles/globals.css";
import "./Styles/homepage.css";
import "./Styles/timeline.css";
import "./Styles/responsive.css";
import "./Styles/multiRangeSlider.css";
import "./Styles/elementor.css";
import "./Styles/formandpopup.css";
import Manpower from "./Manpower";
import Footer from "./components/Footer";
import ContactUs from "./Pages/ContactUs/ContactUs";
import ServicePage from "./ServicePage";

function App() {
  return (
    <div>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Aviation" element={<Aviation />} />
        <Route path="/careers" element={<Manpower />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/service" element={<ServicePage />} />
        <Route path="/service/:serviceTitle" element={<ServicePage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
